
/**
 * @roxi/routify 2.18.3
 * File generated Mon Nov 08 2021 10:04:05 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.3"
export const __timestamp = "2021-11-08T10:04:05.810Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "path": "/docs/:source/:tag/:category/:file",
                      "id": "_docs__source__tag__category__file",
                      "component": () => import('../src/pages/docs/[source]/[tag]/[category]/[file].svelte').then(m => m.default)
                    }
                  ],
                  "path": "/docs/:source/:tag/:category"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "path": "/docs/:source/:tag/class/:class",
                      "id": "_docs__source__tag_class__class",
                      "component": () => import('../src/pages/docs/[source]/[tag]/class/[class].svelte').then(m => m.default)
                    }
                  ],
                  "path": "/docs/:source/:tag/class"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "path": "/docs/:source/:tag/typedef/:typedef",
                      "id": "_docs__source__tag_typedef__typedef",
                      "component": () => import('../src/pages/docs/[source]/[tag]/typedef/[typedef].svelte').then(m => m.default)
                    }
                  ],
                  "path": "/docs/:source/:tag/typedef"
                }
              ],
              "path": "/docs/:source/:tag"
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/docs/:source/index",
              "id": "_docs__source_index",
              "component": () => import('../src/pages/docs/[source]/index.svelte').then(m => m.default)
            }
          ],
          "path": "/docs/:source"
        }
      ],
      "path": "/docs"
    },
    {
      "isPage": true,
      "path": "/docs",
      "id": "_docs",
      "component": () => import('../src/pages/docs.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

